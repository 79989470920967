// ---- ---- Estilos (Modo Claro) ---- ---- //

.app {
    width: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    transition: var(--transition);
    color: light-dark(var(--color-black-20), var(--color-white-ff));
    background-color: light-dark(var(--color-white-ff), var(--color-black-18));

    // ---- ---- Estructura ---- ---- //

    .top-bar {
        top: 0px;
        left: 0px;
        z-index: 5;
        width: 100%;
        height: 30px;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        transition: var(--transition);
        background: var(--primary-color);

        .container {
            align-items: center;
            justify-content: center;

            .flex-action {
                flex: 1;
                gap: 8px;
                height: 30px;
                display: flex;
                min-width: 100px;
                max-width: 400px;
                align-items: center;
                justify-content: flex-start;
            }

            .flex-messages {
                flex: 1;
                width: 400px;
                min-width: 400px;
                max-width: 400px;
                height: 30px;
                display: flex;
                overflow: hidden;
                flex-direction: row;

                .message {
                    opacity: 0;
                    height: 30px;
                    width: 400px;
                    display: flex;
                    cursor: pointer;
                    position: absolute;
                    visibility: hidden;
                    align-items: center;
                    justify-content: center;
                    color: var(--color-white-ff);
                    transition: var(--transition);
                    font: normal 10px var(--font-mono-code);

                    a {
                        color: inherit;
                        padding: 2px 4px;
                        border-radius: 4px;

                        &:focus-visible {
                            outline: none;
                            color: var(--color-white-ff);
                            border: 1px solid var(--color-white-ff);
                            background-color: var(--color-inactive);
                        }
                    }

                    &.show {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            .flex-socials {
                flex: 1;
                min-width: 160px;
                max-width: 400px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .socials-list {
                    gap: 4px;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    list-style: none;

                    .social-item {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .social-link {
                            width: 20px;
                            height: 20px;
                            display: flex;
                            border-radius: 4px;
                            align-items: center;
                            justify-content: center;
                            color: var(--color-inactive);
                            transition: var(--transition);
                            border: 1px dashed transparent;

                            &:hover {
                                color: var(--color-white-ff);
                            }

                            &:focus-visible {
                                outline: none;
                                color: var(--color-white-ff);
                                border-color: var(--color-white-ff);
                            }
                        }
                    }
                }
            }
        }
    }

    .header {
        left: 0px;
        top: 30px;
        z-index: 12;
        width: 100%;
        height: 80px;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        transition: var(--transition);
        background-color: light-dark(var(--color-white-ff), var(--color-black-20));
        border-top: 1px solid light-dark(var(--color-white-e8), var(--color-black-35));
        border-bottom: 1px solid light-dark(var(--color-white-e8), var(--color-black-35));

        .container {
            align-items: center;
            justify-content: space-between;

            .flex {
                gap: 8px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                hr {
                    width: 1px;
                    height: 80px;
                    border: none;
                    pointer-events: none;
                    transition: var(--transition);
                    background-color: var(--border-color);
                }

                .logo {
                    display: flex;
                    color: inherit;
                    cursor: pointer;
                    overflow: hidden;
                    align-self: center;
                    text-decoration: none;

                    img {
                        width: 200px;
                        height: 30px;
                        object-fit: cover;
                        pointer-events: none;
                        object-position: center;
                        background: var(--primary-color);
                    }

                    span {
                        min-width: max-content;
                        text-transform: uppercase;
                        transition: var(--transition);
                        font: bold 22px var(--font-display);
                        color: light-dark(var(--color-black-20), var(--color-white-ff));
                    }

                    &:focus-visible {
                        outline: none;
                        text-shadow: 1px 1px 2px var(--color-blue);
                    }
                }

                .nav-menu,
                .nav-icons {
                    height: 80px;
                    display: flex;
                    align-items: center;

                    .nav-list {
                        gap: 8px;
                        margin: 0;
                        padding: 0;
                        display: flex;
                        list-style: none;
                        align-items: center;

                        .nav-item {
                            height: 80px;
                            display: flex;
                            position: relative;
                            align-items: center;
                            justify-content: center;

                            .nav-link {
                                height: 100%;
                                display: flex;
                                color: inherit;
                                padding: 0 6px;
                                user-select: none;
                                align-items: center;
                                text-decoration: none;
                                min-width: max-content;
                                text-transform: capitalize;
                                transition: var(--transition);
                                font: normal 14px var(--font-mono-code);
                                color: light-dark(var(--color-black-20), var(--color-white-ff));

                                &:hover {
                                    text-decoration: underline;
                                }

                                &:focus-visible {
                                    outline: none;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }

                select {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    cursor: pointer;
                    appearance: none;
                    user-select: none;
                    text-align: center;
                    letter-spacing: .4px;
                    box-sizing: content-box;
                    border: 1px solid var(--border-color);
                    font: normal 10px var(--font-mono-code);


                    option {
                        background-color: light-dark(var(--color-white-ff), var(--color-black-20));

                        &:checked {
                            background: var(--color-blue);
                        }
                    }

                    &:focus-visible {
                        outline: none;
                    }
                }

                &:first-child {
                    justify-content: flex-start;
                }

                &:last-child {
                    justify-content: flex-end;
                }
            }
        }
    }

    .menu {
        top: 0px;
        bottom: 0px;
        right: -300px;
        z-index: 16;
        width: 300px;
        height: 100vh;
        display: flex;
        position: fixed;
        flex-direction: column;
        transition: var(--transition);
        background-color: light-dark(var(--color-white-ff), var(--color-black-20));
        border-left: 1px solid light-dark(var(--color-white-e8), var(--color-black-35));

        .menu-header {
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid light-dark(var(--color-white-e8), var(--color-black-35));

            .container {
                margin: 0 40px;
                align-items: center;
                justify-content: space-between;

                .menu-title {
                    font: bold 12px var(--font-mono);
                    color: light-dark(var(--color-black-20), var(--color-white-ff));
                }
            }
        }

        .menu-content {
            width: 100%;
            display: flex;
            overflow-y: auto;
            align-items: center;
            flex-direction: column;

            .nav-menu {
                width: 100%;

                .nav-list {
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    display: flex;
                    list-style: none;
                    flex-direction: column;

                    .nav-item {
                        width: 100%;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid var(--border-color);

                        .nav-link {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            text-indent: 40px;
                            position: relative;
                            align-items: center;
                            text-decoration: none;
                            justify-content: flex-start;
                            transition: var(--transition);
                            font: normal 12px var(--font-mono-code);
                            color: light-dark(var(--color-black-75), var(--color-white-99));

                            svg {
                                right: 40px;
                                position: absolute;
                            }

                            &:hover {
                                color: light-dark(var(--color-black-20), var(--color-white-ff));
                            }

                            &:focus-visible {
                                outline: none;
                                color: var(--color-black-00);
                                border-top: 1px dashed var(--color-black-00);
                                border-bottom: 1px dashed var(--color-black-00);
                            }
                        }
                    }
                }
            }
        }

        &.show {
            right: 0px;
        }
    }

    .main {
        width: 100%;
        display: flex;
        min-height: 70vh;
        padding: 40px 0px;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: calc(80px + 30px);

        .section {
            width: 100%;
            display: flex;
            justify-content: center;

            .container {
                flex-direction: column;
            }
        }
    }

    .footer {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        transition: var(--transition);
        // background-color: light-dark(var(--color-white-f5), var(--color-black-28));
        border-top: 1px solid light-dark(var(--color-white-e8), var(--color-black-35));

        .container {
            width: 100%;
            padding: 30px 0;
            flex-direction: column;
            // border-top: 1px solid var(--border-color);

            hr {
                background-color: var(--border-color);
                // background-color: light-dark(var(--color-white-99), var(--color-black-35));
            }

            .row-socials {
                width: 100%;
                display: flex;
                padding: 0 0 10px;
                align-items: center;
                justify-content: center;

                .socials-list {
                    gap: 20px;
                    padding: 0;
                    width: 100%;
                    display: flex;
                    list-style: none;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    .social-item {

                        .social-link {
                            font-size: 20px;
                            transition: var(--transition);
                            color: light-dark(var(--color-black-20), var(--color-white-ff));
                        }
                    }
                }
            }

            .row-copyright {
                gap: 4px;
                display: flex;
                padding: 10px 0 20px 0;
                align-items: center;
                justify-content: center;
                // background-color: rosybrown;

                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // background-color: seagreen;

                    &:nth-child(1) {
                        font-size: 11px;
                    }

                    &:nth-child(2) {
                        font: normal 11px var(--font-mono);
                    }
                }

                p {
                    margin: 0;
                    text-transform: uppercase;
                    transition: var(--transition);
                    font: normal 10px var(--font-mono-code);
                    color: light-dark(var(--color-black-20), var(--color-white-ff));
                }
            }
        }
    }

    // ---- ---- Classes ---- ---- //

    .container,
    .wrapper {
        width: 100%;
        display: flex;
        margin: 0 28px;
        max-width: var(--wrapper);
    }

    .grid {
        gap: 8px;
        width: 100%;
        display: grid;
        overflow: hidden;
        grid-template-rows: repeat(1, 1fr);
        grid-template-columns: repeat(24, 1fr);
    }

    hr {
        width: 100%;
        height: 1px;
        border: none;
        margin: 10px 0px;
        pointer-events: none;
        transition: var(--transition);
        background-color: light-dark(var(--color-white-e8), var(--color-black-35));
    }

    .banner {
        height: 815px; // 815px, 842px, 100vh
        display: flex;
        margin: -40px 0px;
        align-items: center;
        justify-content: center;
        transition: var(--transition);

        .container {

            .banner-box {
                gap: 14px;
                padding: 40px;
                display: flex;
                align-items: center;
                border-radius: 12px;
                flex-direction: column;
                backdrop-filter: blur(10px);
                background-color: transparent;
                border: 1px solid var(--color-black-65);

                .title {
                    margin: 0px;
                    color: white;
                    text-wrap: balance;
                    text-align: center;
                    text-transform: capitalize;
                    font: bold 40px var(--font-mono-code);
                    line-height: 60px;
                }

                .subtitle {
                    color: var(--color-white-e8);
                    text-wrap: balance;
                    text-align: center;
                    text-transform: capitalize;
                    font: normal 14px var(--font-mono-code);
                    line-height: 28px;
                }

                .banner-action {
                    gap: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: calc(40px - 14px);
                }
            }
        }
    }

    .row-title,
    .row-action {
        gap: 20px;
        width: 100%;
        display: flex;
        padding: 34px 0px;
        align-items: center;
    }

    .row-title {
        justify-content: space-between;

        h1,
        h2 {
            margin: 0;
            user-select: none;
            font: bold 24px var(--font-mono);
        }
    }

    .row-caption {
        gap: 14px;
        display: flex;
        align-items: center;
        padding-bottom: 40px;
        flex-direction: column;

        h1,
        h2,
        p {
            margin: 0;
            width: 100%;
            text-align: center;
            text-wrap: balance;
        }

        h1,
        h2 {
            font: bold 24px var(--font-mono-code);
        }

        .line {
            height: 1px;
            width: 75px;
            background-color: var(--border-color);
        }

        p {
            display: none;
            font: normal 14px var(--font-mono);
            line-height: 30px;
        }
    }

    .row-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .grid {
            padding: 0 !important;
        }
    }

    .row-action {
        justify-content: flex-end;
    }

    .row-buttons {
        gap: 8px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 40px;
        justify-content: center;
    }

    .title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: rosybrown;
        margin-bottom: 40px;

        h1 {
            // background-color: rebeccapurple;
            font: bold 26px var(--font-mono);
        }
    }

    .content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
    }

    .link-text {
        color: light-dark(black, white);
    }

    .form {
        width: 100%;
        display: flex;
        overflow: hidden;
        padding: 14px 0px;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        border: 1px solid var(--border-color);
        background-color: var(--color-white-ff);

        .block,
        .form-block {
            gap: 8px;
            width: calc(100% - 30px);
            display: flex;
            position: relative;
            margin-bottom: 8px;
            align-items: center;
            justify-content: space-between;

            label {
                width: 100%;
            }

            input,
            select {
                width: 100%;
                height: 34px;
                border: none;
                padding: 0 14px;
                appearance: none;
                transition: var(--transition);
                font: normal 12px var(--font-mono);
                border: 1px solid var(--border-color);
                background-color: var(--color-white-fa);

                &:focus,
                &:focus-visible {
                    outline: none;
                    border: 1px solid var(--color-blue);
                }
            }

            textarea {
                width: 100%;
                padding: 14px;
                font: normal 14px var(--font-mono);
                border: 1px solid var(--border-color);
                background-color: var(--color-white-fa);

                &:focus,
                &:focus-visible {
                    outline: none;
                    border: 1px solid var(--color-blue);
                }
            }

            .group {
                gap: 8px;
                display: flex;
                align-items: center;
            }
        }

        fieldset {
            width: calc(100% - 60px);
            display: flex;
            flex-direction: column;
        }
    }

    .block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-items: center;

        h2 {
            font: bold 18px var(--font-mono-code);
        }

        p {
            gap: 8px;
            text-align: center;
        }

        .row-wrap {
            gap: 8px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: 8px 0px 18px 0px;

            .item {
                gap: 2px;
                height: 24px;
                display: flex;
                cursor: pointer;
                padding: 0px 6px;
                user-select: none;
                border-radius: 4px;
                align-items: center;
                // pointer-events: none;
                border: 1px solid var(--border-color);
                background-color: var(--color-inactive);

                img {
                    object-fit: cover;
                    margin-left: -2px;
                    pointer-events: none;
                    object-position: center;
                }
            }
        }

        &.hidden {
            display: none;
        }
    }

    // ---- ---- Componentes ---- ---- //

    .service-item {
        width: 100%;
        display: flex;
        padding: 0 0 40px;
        align-items: flex-start;
        justify-content: flex-start;

        .service-item-image {
            min-width: 340px;
            width: 340px;
            max-width: 340px;
            // height: 200px;
            display: flex;
            overflow: hidden;
            margin-right: 40px;
            align-items: center;
            // border-radius: 32px;
            transition: var(--transition);
            border: 2px solid light-dark(var(--color-white-e8), var(--color-black-35));

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                aspect-ratio: 16/9;
                pointer-events: none;
                object-position: center;
                animation: var(--loading-dark);
            }
        }

        .service-item-data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h2,
            p {
                text-wrap: balance;
                letter-spacing: .4px;
                transition: var(--transition);
                color: light-dark(var(--color-black-20), var(--color-white-ff));
            }

            h2 {
                font-size: 24px;
                margin: 0 0 18px;
                font-weight: bold;
                font-family: var(--font-mono-code);
            }

            p {
                font-size: 14px;
                margin: 0 0 20px;
                font-weight: normal;
                line-height: 2 !important;
                font-family: var(--font-mono-code);
            }
        }

        &:first-child {
            padding-top: 40px;
        }

        &:nth-child(even) {
            padding: 40px 0 0;
            align-items: flex-end;
            transform: rotate(180deg);

            .service-item-image,
            .service-item-data {
                transform: rotate(180deg);
            }

            .service-item-data {
                align-items: flex-end;

                h2,
                p {
                    text-align: right;
                }
            }
        }
    }

    .service-box {
        width: 100%;
        display: flex;
        cursor: default;
        overflow: hidden;
        border-radius: 8px;
        flex-direction: column;
        transition: var(--transition);
        border: 1px solid var(--border-color);
        background-color: light-dark(var(--color-white-e8), var(--color-black-20));

        .service-box-image {
            display: flex;
            min-height: 165px;
            align-items: center;
            justify-content: center;
            animation: var(--loading);
            // user-select: none;

            img {
                width: 100%;
                pointer-events: none;
            }
        }

        .service-box-data {
            gap: 14px;
            display: flex;
            padding: 20px 0;
            align-items: center;
            flex-direction: column;

            h2 {
                margin: 0;
                text-align: center;
                text-wrap: balance;
                width: calc(100% - 80px);
                text-transform: capitalize;
                font: bold 16px var(--font-display);
            }

            .ghost-button {
                height: 30px;
                padding: 0 20px;
                border-radius: 6px;
                border-color: var(--border-color);
                font: normal 10px var(--font-mono-code);
            }
        }

        &:hover {
            box-shadow: 2px 2px 8px light-dark(var(--color-white-e8), var(--color-black-35));
        }
    }

    .service-box-load {
        width: 100%;
        display: flex;
        cursor: progress;
        overflow: hidden;
        border-radius: 8px;
        flex-direction: column;
        border: 1px solid var(--border-color);

        .service-box-load-image {
            width: 100%;
            cursor: progress;
            min-height: 165px;
            animation: var(--loading);
        }

        .service-box-load-data {
            gap: 14px;
            width: 100%;
            display: flex;
            padding: 20px 0px;
            align-items: center;
            flex-direction: column;

            .row-title {
                gap: 8px;
                width: 100%;
                padding: 0px;
                display: flex;
                flex-direction: column;

                .title,
                .subtitle {
                    height: 14px;
                    cursor: progress;
                    border-radius: 4px;
                    animation: var(--loading);
                }

                .title {
                    width: 200px;
                }

                .subtitle {
                    width: 160px;
                }
            }

            .button {
                width: 148px;
                height: 30px;
                border: none;
                cursor: progress;
                border-radius: 6px;
                animation: var(--loading);
            }
        }
    }

    .pricing-box {
        grid-column: span 6;
        display: flex;
        overflow: hidden;
        transition: var(--transition);
        border: 1px solid var(--border-color);
        background-color: light-dark(var(--color-white-f5), var(--color-black-20));

        .wrapper {
            gap: 20px;
            width: 100%;
            margin: 20px;
            display: flex;
            flex-direction: column;

            .title {
                width: 100%;
                margin: 0px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                transition: var(--transition);
                font: bold 12px var(--font-mono-code);
                color: light-dark(var(--color-black-75), var(--color-white-99));
            }

            .price {
                text-align: left;
                transition: var(--transition);
                font: bold 24px var(--font-mono-code);
                color: light-dark(var(--color-black-20), var(--color-white-ff));
            }

            .line {
                border-bottom: 2px dashed var(--color-black-95);
            }

            .features {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    display: flex;
                    margin: 14px 0px;
                    overflow: hidden;
                    align-items: flex-start;
                    color: var(--color-black-65);
                    transition: var(--transition);

                    b {
                        font: normal 12px var(--font-mono-code);
                    }

                    .icon {
                        margin-right: 8px;
                    }

                    .text {
                        // text-wrap: balance;
                        text-wrap: nowrap;
                        font: normal 12px var(--font-mono-code);
                        line-height: 2;
                    }

                    &:first-child {
                        margin-top: 0px;
                    }

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }

            a {
                display: none;
                pointer-events: none;
            }
        }

        &:hover {

            .wrapper {

                .line {
                    border-color: light-dark(var(--color-black-20), var(--color-white-ff));
                }

                .features {
                    li {

                        b,
                        .icon,
                        .text {
                            color: (var(--color-black-28), var(--color-white-99)) !important;
                        }
                    }
                }

                .ghost-button {
                    color: light-dark(var(--color-white-ff), var(--color-black-20));
                    background-color: light-dark(var(--color-black-20), var(--color-white-ff));
                }
            }
        }
    }

    .project-box {
        grid-column: span 8;
        width: 100%;
        display: flex;
        cursor: default;
        overflow: hidden;
        flex-direction: column;
        transition: var(--transition);
        border: 1px solid var(--border-color);
        background-color: light-dark(var(--color-white-ff), var(--color-black-20));

        .project-box-picture {
            width: 100%;
            min-height: 292px;
            max-height: 292px;
            display: flex;
            overflow: hidden;
            position: relative;
            align-items: center;
            justify-content: center;
            background: var(--primary-color);
            border-bottom: 1px solid light-dark(var(--color-white-e8), var(--color-black-35));

            .project-box-image {
                z-index: 2;
                width: 100%;
                height: 100%;
                display: flex;
                position: absolute;
                object-fit: cover;
                pointer-events: none;
                object-position: center;
            }

            .projects-box-default {
                z-index: 1;
                height: 100%;
                display: flex;
                object-fit: cover;
                pointer-events: none;
                object-position: center;
            }

            iframe {
                width: 100%;
                min-height: 100%;
                pointer-events: none;
                position: relative;
                overflow: hidden;
                border: none;
            }
        }

        .project-box-data {
            gap: 10px;
            display: flex;
            padding: 16px 12px;
            align-items: center;
            flex-direction: column;

            .project-box-title {
                width: calc(100% - 24px);
                min-width: max-content;
                text-align: center;
                letter-spacing: .4px;
                font: bold 16px var(--font-mono-code);
            }

            .project-box-label {
                width: calc(100% - 24px);
                text-align: center;
                margin-bottom: 10px;
                letter-spacing: .4px;
                font: normal 12px var(--font-mono-code);
            }

            .primary-button {
                height: 34px;
                padding: 0 14px;
                background: transparent;
                border-color: var(--border-color);
                font: normal 12px var(--font-mono-code);
                color: light-dark(var(--color-black-20), var(--color-white-ff));

                &:hover {
                    color: light-dark(var(--color-white-ff), var(--color-black-20));
                    background-color: light-dark(var(--color-black-20), var(--color-white-ff));
                }
            }
        }

        &:hover {
            box-shadow: 2px 2px 8px light-dark(var(--color-white-e8), var(--color-black-35));
        }
    }

    .project-box-load {
        grid-column: span 8;
        width: 100%;
        display: flex;
        cursor: progress;
        overflow: hidden;
        flex-direction: column;
        transition: var(--transition);
        border: 1px solid var(--border-color);

        .project-box-load-image {
            width: 100%;
            cursor: progress;
            min-height: 292px;
            max-height: 292px;
            animation: var(--loading);
        }

        .project-box-load-data {
            gap: 10px;
            display: flex;
            padding: 16px 12px;
            align-items: center;
            flex-direction: column;

            .title {
                margin: 0px;
                width: 240px;
                height: 21px;
                cursor: progress;
                animation: var(--loading);
            }

            .label {
                width: 160px;
                height: 16px;
                cursor: progress;
                margin-top: -2px;
                margin-bottom: 10px;
                animation: var(--loading);
            }

            .button {
                cursor: progress;
            }
        }
    }

    // ---- ---- Páginas ---- ---- //

    .about {

        .about-photo {
            display: flex;
            flex-direction: column;
            grid-column: span calc(24 / 2);

            figure {
                margin: 0;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                width: calc(100% - 20px);

                img {
                    width: 100%;
                    aspect-ratio: 1;
                    object-fit: cover;
                    pointer-events: none;
                    object-position: center;
                    transition: var(--transition);
                    border: 2px solid var(--border-color);
                }
            }
        }

        .about-details {
            display: flex;
            flex-direction: column;
            grid-column: span calc(24 / 2);

            h2 {
                margin: 0px;
                transition: var(--transition);
                font: bold 30px var(--font-mono-code);
            }

            h3 {
                transition: var(--transition);
                font: normal 16px var(--font-mono-code);
            }

            p {
                transition: var(--transition);
                font: normal 13px var(--font-mono-code);
                line-height: 2;
            }
        }
    }

    .service-page {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        .column {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
    }

    .page {
        width: 100%;
        display: flex;
        min-height: 100vh;

        .row {
            width: 100%;
            display: flex;
            flex-direction: row;
        }

        .column {
            gap: 8px;
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .block {
            gap: 8px;
            width: 100%;
            display: flex;
            margin-bottom: 14px;
            flex-direction: column;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0px;
        }

        h1 {
            margin-bottom: 10px;
            font: bold 28px var(--font-mono-code);
        }

        h2 {
            font: normal 18px var(--font-mono-code);
        }

        b,
        strong {
            font: bold 12px var(--font-mono-code);
            line-height: 2;
        }

        p {
            font: normal 12px var(--font-mono-code);
            line-height: 2;
        }

        ul {
            margin: 0px;
            padding: 0px;
            list-style: none;

            li {
                display: flex;
                overflow: hidden;
                align-items: flex-start;
                font: normal 12px var(--font-mono-code);
                line-height: 2.5;

                .icon {
                    margin-right: 8px;
                }
            }
        }

        figure {
            margin: 0px;
            margin-right: 40px;

            img {
                width: 100%;
                aspect-ratio: 1;
                object-fit: cover;
                user-select: none;
                pointer-events: none;
                object-position: center;
                box-sizing: border-box;
            }
        }
    }
}

// ---- ---- Estilos (Modo Oscuro) ---- ---- //

.app.dark {
    color: var(--color-white-ff) !important;
    background-color: var(--color-black-12) !important;

    // ---- ---- Estructura ---- ---- //

    .header {
        color: var(--color-white-ff) !important;
        border-color: var(--color-black-35) !important;
        background-color: var(--color-black-12) !important;

        .container {
            .flex {
                .logo {
                    span {
                        color: var(--color-white-ff) !important;
                    }
                }

                .nav-menu {
                    .nav-list {
                        .nav-item {
                            .nav-link {
                                color: var(--color-white-ff) !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .menu {
        border-color: var(--color-black-35) !important;
        background-color: var(--color-black-20) !important;

        .menu-header {
            border-color: var(--color-black-35) !important;

            .menu-title {
                color: var(--color-white-ff) !important;
            }
        }

        .menu-content {
            .nav-menu {
                .nav-list {
                    .nav-item {
                        border-color: var(--color-black-35) !important;

                        .nav-link {
                            color: var(--color-inactive) !important;

                            &:hover {
                                color: var(--color-white-ff) !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer {
        border-color: var(--color-black-35);

        .container {
            border-color: var(--color-black-35) !important;

            .row-socials {
                .socials-list {
                    .social-item {
                        .social-link {
                            color: var(--color-white-ff) !important;
                        }
                    }
                }
            }

            .row-copyright {
                span {
                    color: var(--color-white-ff) !important;
                }
            }
        }
    }

    // ---- ---- Clases ---- ---- //

    hr {
        background-color: var(--color-black-35) !important;
    }

    .form {
        border-color: var(--color-black-35) !important;
        background-color: var(--color-black-20) !important;

        .block,
        .form-block {

            input,
            textarea {
                color: var(--color-white-ff) !important;
                border-color: var(--color-black-35) !important;
                background-color: var(--color-black-18) !important;
            }
        }
    }

    .row-caption {

        h1,
        h2,
        p {
            color: var(--color-white-ff) !important;
        }
    }

    // ---- ---- Componentes ---- ---- //

    .service-item {
        .service-item-image {
            border-color: var(--color-black-35) !important;
        }

        .service-item-data {

            h2,
            p {
                color: var(--color-white-ff) !important;
            }
        }
    }

    .pricing-box {
        border-color: var(--color-black-35) !important;
        background-color: var(--color-black-20) !important;

        .wrapper {

            .price {
                color: var(--color-white-ff) !important;
            }

            .features {
                li {
                    hr {
                        background-color: var(--color-black-35) !important;
                    }
                }
            }
        }
    }

    .project-box {
        border-color: var(--color-black-35) !important;
        background-color: var(--color-black-20) !important;

        .project-box-picture {
            border-color: var(--color-black-35) !important;
        }

        .project-box-data {

            .project-box-title {
                color: var(--color-white-ff) !important;
            }

            .project-box-label {
                color: var(--color-inactive) !important;
            }
        }

        &:hover {
            box-shadow: 2px 2px 8px var(--color-black-35);
        }
    }

    .project-box-load {
        border-color: var(--color-black-35) !important;
        background-color: var(--color-black-20) !important;

        .project-box-load-image {
            animation: var(--loading-dark) !important;
        }

        .project-box-load-data {

            .title,
            .label {
                animation: var(--loading-dark) !important;
            }
        }
    }

    // ---- ---- Páginas ---- ---- //

    .about {
        .about-photo {
            figure {
                img {
                    border-color: var(--color-black-35) !important;
                }
            }
        }

        .about-details {
            h2 {
                color: var(--color-white-ff);
            }

            h3 {
                color: var(--color-inactive);
            }

            p {
                color: var(--color-white-fa);
            }
        }
    }
}

.app.no-scroll {
    position: fixed;
}

.maintenance {
    width: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    transition: var(--transition);
    color: light-dark(var(--color-black-20), var(--color-white-ff));
    background-color: light-dark(var(--color-white-fa), var(--color-black-18));

    .blur {
        inset: 0;
        z-index: 1;
        width: 100%;
        position: fixed;
        user-select: none;
        pointer-events: none;
        backdrop-filter: blur(10px);
    }

    .container {
        z-index: 2;
        display: flex;
        margin: 0px 32px;
        width: var(--wrapper);
        align-items: flex-start;
        justify-content: center;

        .flex-column {
            gap: 30px;
            width: 100%;
            display: flex;
            padding: 30px 40px;
            align-items: center;
            border-radius: 12px;
            flex-direction: column;
            justify-content: flex-start;

            &:last-child {
                border: 1px solid var(--border-color);
                color: light-dark(var(--color-black-20), var(--color-white-ff));
                background-color: light-dark(var(--color-white-ff), var(--color-black-20));
            }
        }
    }

    .box-notice {
        width: 380px;
        display: flex;
        padding: 20px 40px;
        border-radius: 12px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-attachment: fixed;
        border: 1px solid var(--border-color);
        background-color: var(--color-black-20);

        h1 {
            width: calc(100% - 40px);
            text-align: center;
            text-wrap: balance;
            font: bold 30px var(--font-mono);
        }

        h3 {
            font: bold 14px var(--font-mono);
        }

        p {
            width: calc(100% - 80px);
            color: #aaaaaa;
            text-wrap: balance;
            text-align: center;
            line-height: 2 !important;
            font: normal 12px var(--font-mono-code);
        }

        .countdown {
            gap: 20px;
            width: 100%;
            display: flex;
            padding: 20px 0px;
            align-items: center;
            justify-content: center;

            .item {
                display: flex;
                align-items: center;
                flex-direction: column;

                span {

                    &:first-child {
                        color: var(--color-white-ff);
                        font: bold 14px var(--font-mono);
                    }

                    &:last-child {
                        font: normal 12px var(--font-mono);
                        color: #aaaaaa;
                    }
                }
            }
        }
    }

    .row-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1,
        h2 {
            margin: 0px;
            text-wrap: balance;
            font: bold 20px var(--font-mono);
        }
    }

    .row-content {
        width: 100%;
        display: flex;

        .form {
            gap: 14px;
            width: 100%;
            display: flex;
            flex-direction: column;

            .block {
                gap: 14px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                input[type="text"],
                input[type="email"],
                textarea {
                    width: 100%;
                    height: 34px;
                    text-indent: 14px;
                    border-radius: 4px;
                    line-height: 2 !important;
                    font: normal 12px var(--font-mono);
                    border: 1px solid var(--border-color);
                    background-color: light-dark(var(--color-white-fa), var(--color-black-18));

                    &:focus-visible {
                        outline: none;
                    }
                }

                textarea {
                    padding: 10px 14px;
                    text-indent: 0px;
                    min-width: 514px;
                    max-width: 514px;
                    min-height: 200px;
                    max-height: 200px;
                    text-align: left;
                    text-wrap: balance;
                    overflow: hidden auto;

                    &::selection {
                        background-color: var(--color-blue);
                    }
                }

                .icon-button {
                    width: 38px;
                    height: 38px;
                    display: flex;
                    cursor: pointer;
                    min-width: 38px;
                    min-height: 38px;
                    user-select: none;
                    border-radius: 4px;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid var(--border-color);
                    color: light-dark(var(--color-black-20), var(--color-white-ff));
                    background-color: light-dark(var(--color-white-fa), var(--color-black-18));
                }

                .primary-button {
                    width: 100%;
                    height: 38px;
                    border: none;
                    display: flex;
                    cursor: pointer;
                    border-radius: 4px;
                    align-items: center;
                    letter-spacing: .4px;
                    justify-content: center;
                    color: var(--color-white-ff);
                    font: normal 12px var(--font-mono);
                    background-color: light-dark(var(--color-black-20), var(--color-black-12));
                }
            }
        }
    }

    .row-socials {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .socials-list {
            gap: 14px;
            margin: 0;
            padding: 0;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            align-items: center;
            justify-content: center;

            .social-item {
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;

                .social-link {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    font-size: 20px;
                    align-items: center;
                    justify-content: center;
                    transition: var(--transition);
                    color: light-dark(var(--color-black-20), var(--color-inactive));
                }
            }
        }
    }
}

// ---- ---- Modos (Claro & Oscuro) ---- ---- //

@media (prefers-color-scheme: light) {

    .banner,
    .maintenance {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left bottom;
        background-image: url(../../../public/assets/city-i.png);
    }
}

@media (prefers-color-scheme: dark) {

    .banner,
    .maintenance {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right bottom;
        background-image: url(../../../public//assets/city-ii.png);
    }
}

// ---- ---- Estilos (Laptop, Tablet, Móvil) ---- ---- //

@media screen and (max-width: 1242px) {

    .pricing-box {
        grid-column: span 8 !important;
    }

    .project-box,
    .project-box-load {

        .project-box-picture,
        .project-box-load-image {
            min-height: 222px !important;

            .projects-box-default {
                max-height: 145px !important;
            }
        }
    }
}

@media screen and (max-width: 64em) {

    // ---- ---- Estructura ---- ---- //

    .top-bar {
        .container {
            .flex-action {
                display: none !important;
            }
        }
    }

    .header {
        .container {
            .flex {
                .logo {
                    span {
                        font-size: 20px !important;
                    }
                }

                .nav-menu {
                    display: none !important;
                }
            }
        }
    }

    // ---- ----  Clases ---- ---- //

    .banner {
        height: calc(100vh - 110px) !important;

        .container {
            .banner-box {

                .title {
                    font-size: 28px !important;
                }

                .subtitle {
                    font-size: 12px !important;
                }
            }
        }
    }

    // ---- ---- components ---- ---- //

    .pricing-box {
        grid-column: span 12 !important;
    }

    .project-box {
        grid-column: span 12 !important;
    }

    .service-item {
        .service-item-image {
            min-width: 300px !important;
        }

        .service-item-data {

            h2 {
                font-size: 20px !important;
                line-height: 1.8 !important;
                margin-bottom: 10px !important;
            }

            p {
                font-size: 14px !important;
            }
        }
    }

    .service-box-load {
        grid-column: span 12 !important;

        .service-box-load-image {
            height: 204.94px;
        }

        .service-box-load-data {

            .row-title {

                .title {
                    width: 240px !important;
                }

                .subtitle {
                    display: none !important;
                }
            }
        }
    }

    // ---- ---- pages ---- ---- //

    .about {

        .about-photo {
            margin-bottom: 20px;
            grid-column: span 24 !important;

            figure {
                width: 100% !important;

                img {
                    width: calc(100% - 4px) !important;
                }
            }
        }

        .about-details {
            align-items: center !important;
            grid-column: span 24 !important;

            p {
                text-align: center !important;
                text-wrap: balance !important;
            }
        }
    }
}

@media screen and (max-width: 40em) {

    // ---- ---- Estructura --- ---- //

    .top-bar {
        .container {

            .flex-action,
            .flex-socials {
                display: none !important;
            }
        }
    }

    // ---- ---- Clases --- ---- //

    .banner {
        .container {
            .banner-box {
                padding: 40px 20px !important;

                .title {
                    font-size: 22px !important;
                }

                .subtitle {
                    font-size: 10px !important;
                }
            }
        }
    }

    // ---- ---- Componentes ---- ---- //

    .service-item {
        flex-direction: column;

        .service-item-image {
            min-width: auto !important;
            width: 100% !important;
            max-width: 100% !important;
        }

        .service-item-data {
            margin-top: 18px !important;
            align-items: center !important;

            h2,
            p {
                text-align: center !important;
            }

            h2 {
                font-size: 16px !important;
            }

            p {
                font-size: 12px !important;
            }
        }

        &:nth-child(even) {
            padding: 0 0 40px !important;
            transform: rotate(0deg) !important;

            .service-item-image {
                margin-right: 0 !important;
            }

            .service-item-image,
            .service-item-data {
                transform: rotate(0deg) !important;
            }
        }
    }

    .pricing-box {
        grid-column: span 24 !important;
    }

    .project-box {
        grid-column: span 24 !important;

        .project-box-picture {
            height: 210px !important;
        }
    }

    .project-box-load {
        grid-column: span 24 !important;
    }

    // ---- ----- Páginas ---- ---- //

    .maintenance {

        .box-notice {
            padding: 20px 0px;

            h1 {
                font-size: 24px
            }
        }
    }
}

// ---- ---- Importaciones ---- ---- //

@import url(./comps.css);

@import url(./fonts.css);

@import url(./global.css);

@import url(./button.css);