body {
  margin: 0;
  color: light-dark(#181818, #ffffff);
  background-color: light-dark(#ffffff, #181818);
  /* font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: var(--font-mono-code);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 2;
}

code {
  font-family: "Space Mono", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
