@import url(./comps.css);
@import url(./fonts.css);
@import url(./global.css);
@import url(./button.css);
.app {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  transition: var(--transition);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
  background-color: light-dark(var(--color-white-ff), var(--color-black-18));
}
.app .top-bar {
  top: 0px;
  left: 0px;
  z-index: 5;
  width: 100%;
  height: 30px;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  background: var(--primary-color);
}
.app .top-bar .container {
  align-items: center;
  justify-content: center;
}
.app .top-bar .container .flex-action {
  flex: 1;
  gap: 8px;
  height: 30px;
  display: flex;
  min-width: 100px;
  max-width: 400px;
  align-items: center;
  justify-content: flex-start;
}
.app .top-bar .container .flex-messages {
  flex: 1;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  height: 30px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
}
.app .top-bar .container .flex-messages .message {
  opacity: 0;
  height: 30px;
  width: 400px;
  display: flex;
  cursor: pointer;
  position: absolute;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  color: var(--color-white-ff);
  transition: var(--transition);
  font: normal 10px var(--font-mono-code);
}
.app .top-bar .container .flex-messages .message a {
  color: inherit;
  padding: 2px 4px;
  border-radius: 4px;
}
.app .top-bar .container .flex-messages .message a:focus-visible {
  outline: none;
  color: var(--color-white-ff);
  border: 1px solid var(--color-white-ff);
  background-color: var(--color-inactive);
}
.app .top-bar .container .flex-messages .message.show {
  opacity: 1;
  visibility: visible;
}
.app .top-bar .container .flex-socials {
  flex: 1;
  min-width: 160px;
  max-width: 400px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.app .top-bar .container .flex-socials .socials-list {
  gap: 4px;
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}
.app .top-bar .container .flex-socials .socials-list .social-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .top-bar .container .flex-socials .socials-list .social-item .social-link {
  width: 20px;
  height: 20px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  color: var(--color-inactive);
  transition: var(--transition);
  border: 1px dashed transparent;
}
.app .top-bar .container .flex-socials .socials-list .social-item .social-link:hover {
  color: var(--color-white-ff);
}
.app .top-bar .container .flex-socials .socials-list .social-item .social-link:focus-visible {
  outline: none;
  color: var(--color-white-ff);
  border-color: var(--color-white-ff);
}
.app .header {
  left: 0px;
  top: 30px;
  z-index: 12;
  width: 100%;
  height: 80px;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  background-color: light-dark(var(--color-white-ff), var(--color-black-20));
  border-top: 1px solid light-dark(var(--color-white-e8), var(--color-black-35));
  border-bottom: 1px solid light-dark(var(--color-white-e8), var(--color-black-35));
}
.app .header .container {
  align-items: center;
  justify-content: space-between;
}
.app .header .container .flex {
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .header .container .flex hr {
  width: 1px;
  height: 80px;
  border: none;
  pointer-events: none;
  transition: var(--transition);
  background-color: var(--border-color);
}
.app .header .container .flex .logo {
  display: flex;
  color: inherit;
  cursor: pointer;
  overflow: hidden;
  align-self: center;
  text-decoration: none;
}
.app .header .container .flex .logo img {
  width: 200px;
  height: 30px;
  -o-object-fit: cover;
     object-fit: cover;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  background: var(--primary-color);
}
.app .header .container .flex .logo span {
  min-width: -moz-max-content;
  min-width: max-content;
  text-transform: uppercase;
  transition: var(--transition);
  font: bold 22px var(--font-display);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .header .container .flex .logo:focus-visible {
  outline: none;
  text-shadow: 1px 1px 2px var(--color-blue);
}
.app .header .container .flex .nav-menu,
.app .header .container .flex .nav-icons {
  height: 80px;
  display: flex;
  align-items: center;
}
.app .header .container .flex .nav-menu .nav-list,
.app .header .container .flex .nav-icons .nav-list {
  gap: 8px;
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.app .header .container .flex .nav-menu .nav-list .nav-item,
.app .header .container .flex .nav-icons .nav-list .nav-item {
  height: 80px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.app .header .container .flex .nav-menu .nav-list .nav-item .nav-link,
.app .header .container .flex .nav-icons .nav-list .nav-item .nav-link {
  height: 100%;
  display: flex;
  color: inherit;
  padding: 0 6px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-items: center;
  text-decoration: none;
  min-width: -moz-max-content;
  min-width: max-content;
  text-transform: capitalize;
  transition: var(--transition);
  font: normal 14px var(--font-mono-code);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .header .container .flex .nav-menu .nav-list .nav-item .nav-link:hover,
.app .header .container .flex .nav-icons .nav-list .nav-item .nav-link:hover {
  text-decoration: underline;
}
.app .header .container .flex .nav-menu .nav-list .nav-item .nav-link:focus-visible,
.app .header .container .flex .nav-icons .nav-list .nav-item .nav-link:focus-visible {
  outline: none;
  text-decoration: underline;
}
.app .header .container .flex select {
  width: 32px;
  height: 32px;
  display: flex;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-align: center;
  letter-spacing: 0.4px;
  box-sizing: content-box;
  border: 1px solid var(--border-color);
  font: normal 10px var(--font-mono-code);
}
.app .header .container .flex select option {
  background-color: light-dark(var(--color-white-ff), var(--color-black-20));
}
.app .header .container .flex select option:checked {
  background: var(--color-blue);
}
.app .header .container .flex select:focus-visible {
  outline: none;
}
.app .header .container .flex:first-child {
  justify-content: flex-start;
}
.app .header .container .flex:last-child {
  justify-content: flex-end;
}
.app .menu {
  top: 0px;
  bottom: 0px;
  right: -300px;
  z-index: 16;
  width: 300px;
  height: 100vh;
  display: flex;
  position: fixed;
  flex-direction: column;
  transition: var(--transition);
  background-color: light-dark(var(--color-white-ff), var(--color-black-20));
  border-left: 1px solid light-dark(var(--color-white-e8), var(--color-black-35));
}
.app .menu .menu-header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid light-dark(var(--color-white-e8), var(--color-black-35));
}
.app .menu .menu-header .container {
  margin: 0 40px;
  align-items: center;
  justify-content: space-between;
}
.app .menu .menu-header .container .menu-title {
  font: bold 12px var(--font-mono);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .menu .menu-content {
  width: 100%;
  display: flex;
  overflow-y: auto;
  align-items: center;
  flex-direction: column;
}
.app .menu .menu-content .nav-menu {
  width: 100%;
}
.app .menu .menu-content .nav-menu .nav-list {
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  list-style: none;
  flex-direction: column;
}
.app .menu .menu-content .nav-menu .nav-list .nav-item {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
}
.app .menu .menu-content .nav-menu .nav-list .nav-item .nav-link {
  width: 100%;
  height: 100%;
  display: flex;
  text-indent: 40px;
  position: relative;
  align-items: center;
  text-decoration: none;
  justify-content: flex-start;
  transition: var(--transition);
  font: normal 12px var(--font-mono-code);
  color: light-dark(var(--color-black-75), var(--color-white-99));
}
.app .menu .menu-content .nav-menu .nav-list .nav-item .nav-link svg {
  right: 40px;
  position: absolute;
}
.app .menu .menu-content .nav-menu .nav-list .nav-item .nav-link:hover {
  color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .menu .menu-content .nav-menu .nav-list .nav-item .nav-link:focus-visible {
  outline: none;
  color: var(--color-black-00);
  border-top: 1px dashed var(--color-black-00);
  border-bottom: 1px dashed var(--color-black-00);
}
.app .menu.show {
  right: 0px;
}
.app .main {
  width: 100%;
  display: flex;
  min-height: 70vh;
  padding: 40px 0px;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 110px;
}
.app .main .section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.app .main .section .container {
  flex-direction: column;
}
.app .footer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: var(--transition);
  border-top: 1px solid light-dark(var(--color-white-e8), var(--color-black-35));
}
.app .footer .container {
  width: 100%;
  padding: 30px 0;
  flex-direction: column;
}
.app .footer .container hr {
  background-color: var(--border-color);
}
.app .footer .container .row-socials {
  width: 100%;
  display: flex;
  padding: 0 0 10px;
  align-items: center;
  justify-content: center;
}
.app .footer .container .row-socials .socials-list {
  gap: 20px;
  padding: 0;
  width: 100%;
  display: flex;
  list-style: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.app .footer .container .row-socials .socials-list .social-item .social-link {
  font-size: 20px;
  transition: var(--transition);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .footer .container .row-copyright {
  gap: 4px;
  display: flex;
  padding: 10px 0 20px 0;
  align-items: center;
  justify-content: center;
}
.app .footer .container .row-copyright span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .footer .container .row-copyright span:nth-child(1) {
  font-size: 11px;
}
.app .footer .container .row-copyright span:nth-child(2) {
  font: normal 11px var(--font-mono);
}
.app .footer .container .row-copyright p {
  margin: 0;
  text-transform: uppercase;
  transition: var(--transition);
  font: normal 10px var(--font-mono-code);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .container,
.app .wrapper {
  width: 100%;
  display: flex;
  margin: 0 28px;
  max-width: var(--wrapper);
}
.app .grid {
  gap: 8px;
  width: 100%;
  display: grid;
  overflow: hidden;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(24, 1fr);
}
.app hr {
  width: 100%;
  height: 1px;
  border: none;
  margin: 10px 0px;
  pointer-events: none;
  transition: var(--transition);
  background-color: light-dark(var(--color-white-e8), var(--color-black-35));
}
.app .banner {
  height: 815px;
  display: flex;
  margin: -40px 0px;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}
.app .banner .container .banner-box {
  gap: 14px;
  padding: 40px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  flex-direction: column;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background-color: transparent;
  border: 1px solid var(--color-black-65);
}
.app .banner .container .banner-box .title {
  margin: 0px;
  color: white;
  text-wrap: balance;
  text-align: center;
  text-transform: capitalize;
  font: bold 40px var(--font-mono-code);
  line-height: 60px;
}
.app .banner .container .banner-box .subtitle {
  color: var(--color-white-e8);
  text-wrap: balance;
  text-align: center;
  text-transform: capitalize;
  font: normal 14px var(--font-mono-code);
  line-height: 28px;
}
.app .banner .container .banner-box .banner-action {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
}
.app .row-title,
.app .row-action {
  gap: 20px;
  width: 100%;
  display: flex;
  padding: 34px 0px;
  align-items: center;
}
.app .row-title {
  justify-content: space-between;
}
.app .row-title h1,
.app .row-title h2 {
  margin: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font: bold 24px var(--font-mono);
}
.app .row-caption {
  gap: 14px;
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  flex-direction: column;
}
.app .row-caption h1,
.app .row-caption h2,
.app .row-caption p {
  margin: 0;
  width: 100%;
  text-align: center;
  text-wrap: balance;
}
.app .row-caption h1,
.app .row-caption h2 {
  font: bold 24px var(--font-mono-code);
}
.app .row-caption .line {
  height: 1px;
  width: 75px;
  background-color: var(--border-color);
}
.app .row-caption p {
  display: none;
  font: normal 14px var(--font-mono);
  line-height: 30px;
}
.app .row-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app .row-content .grid {
  padding: 0 !important;
}
.app .row-action {
  justify-content: flex-end;
}
.app .row-buttons {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
  justify-content: center;
}
.app .title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.app .title h1 {
  font: bold 26px var(--font-mono);
}
.app .content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.app .link-text {
  color: light-dark(black, white);
}
.app .form {
  width: 100%;
  display: flex;
  overflow: hidden;
  padding: 14px 0px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid var(--border-color);
  background-color: var(--color-white-ff);
}
.app .form .block,
.app .form .form-block {
  gap: 8px;
  width: calc(100% - 30px);
  display: flex;
  position: relative;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
}
.app .form .block label,
.app .form .form-block label {
  width: 100%;
}
.app .form .block input,
.app .form .block select,
.app .form .form-block input,
.app .form .form-block select {
  width: 100%;
  height: 34px;
  border: none;
  padding: 0 14px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  transition: var(--transition);
  font: normal 12px var(--font-mono);
  border: 1px solid var(--border-color);
  background-color: var(--color-white-fa);
}
.app .form .block input:focus, .app .form .block input:focus-visible,
.app .form .block select:focus,
.app .form .block select:focus-visible,
.app .form .form-block input:focus,
.app .form .form-block input:focus-visible,
.app .form .form-block select:focus,
.app .form .form-block select:focus-visible {
  outline: none;
  border: 1px solid var(--color-blue);
}
.app .form .block textarea,
.app .form .form-block textarea {
  width: 100%;
  padding: 14px;
  font: normal 14px var(--font-mono);
  border: 1px solid var(--border-color);
  background-color: var(--color-white-fa);
}
.app .form .block textarea:focus, .app .form .block textarea:focus-visible,
.app .form .form-block textarea:focus,
.app .form .form-block textarea:focus-visible {
  outline: none;
  border: 1px solid var(--color-blue);
}
.app .form .block .group,
.app .form .form-block .group {
  gap: 8px;
  display: flex;
  align-items: center;
}
.app .form fieldset {
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}
.app .block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
}
.app .block h2 {
  font: bold 18px var(--font-mono-code);
}
.app .block p {
  gap: 8px;
  text-align: center;
}
.app .block .row-wrap {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 8px 0px 18px 0px;
}
.app .block .row-wrap .item {
  gap: 2px;
  height: 24px;
  display: flex;
  cursor: pointer;
  padding: 0px 6px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border-radius: 4px;
  align-items: center;
  border: 1px solid var(--border-color);
  background-color: var(--color-inactive);
}
.app .block .row-wrap .item img {
  -o-object-fit: cover;
     object-fit: cover;
  margin-left: -2px;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
}
.app .block.hidden {
  display: none;
}
.app .service-item {
  width: 100%;
  display: flex;
  padding: 0 0 40px;
  align-items: flex-start;
  justify-content: flex-start;
}
.app .service-item .service-item-image {
  min-width: 340px;
  width: 340px;
  max-width: 340px;
  display: flex;
  overflow: hidden;
  margin-right: 40px;
  align-items: center;
  transition: var(--transition);
  border: 2px solid light-dark(var(--color-white-e8), var(--color-black-35));
}
.app .service-item .service-item-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 16/9;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  animation: var(--loading-dark);
}
.app .service-item .service-item-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.app .service-item .service-item-data h2,
.app .service-item .service-item-data p {
  text-wrap: balance;
  letter-spacing: 0.4px;
  transition: var(--transition);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .service-item .service-item-data h2 {
  font-size: 24px;
  margin: 0 0 18px;
  font-weight: bold;
  font-family: var(--font-mono-code);
}
.app .service-item .service-item-data p {
  font-size: 14px;
  margin: 0 0 20px;
  font-weight: normal;
  line-height: 2 !important;
  font-family: var(--font-mono-code);
}
.app .service-item:first-child {
  padding-top: 40px;
}
.app .service-item:nth-child(even) {
  padding: 40px 0 0;
  align-items: flex-end;
  transform: rotate(180deg);
}
.app .service-item:nth-child(even) .service-item-image,
.app .service-item:nth-child(even) .service-item-data {
  transform: rotate(180deg);
}
.app .service-item:nth-child(even) .service-item-data {
  align-items: flex-end;
}
.app .service-item:nth-child(even) .service-item-data h2,
.app .service-item:nth-child(even) .service-item-data p {
  text-align: right;
}
.app .service-box {
  width: 100%;
  display: flex;
  cursor: default;
  overflow: hidden;
  border-radius: 8px;
  flex-direction: column;
  transition: var(--transition);
  border: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white-e8), var(--color-black-20));
}
.app .service-box .service-box-image {
  display: flex;
  min-height: 165px;
  align-items: center;
  justify-content: center;
  animation: var(--loading);
}
.app .service-box .service-box-image img {
  width: 100%;
  pointer-events: none;
}
.app .service-box .service-box-data {
  gap: 14px;
  display: flex;
  padding: 20px 0;
  align-items: center;
  flex-direction: column;
}
.app .service-box .service-box-data h2 {
  margin: 0;
  text-align: center;
  text-wrap: balance;
  width: calc(100% - 80px);
  text-transform: capitalize;
  font: bold 16px var(--font-display);
}
.app .service-box .service-box-data .ghost-button {
  height: 30px;
  padding: 0 20px;
  border-radius: 6px;
  border-color: var(--border-color);
  font: normal 10px var(--font-mono-code);
}
.app .service-box:hover {
  box-shadow: 2px 2px 8px light-dark(var(--color-white-e8), var(--color-black-35));
}
.app .service-box-load {
  width: 100%;
  display: flex;
  cursor: progress;
  overflow: hidden;
  border-radius: 8px;
  flex-direction: column;
  border: 1px solid var(--border-color);
}
.app .service-box-load .service-box-load-image {
  width: 100%;
  cursor: progress;
  min-height: 165px;
  animation: var(--loading);
}
.app .service-box-load .service-box-load-data {
  gap: 14px;
  width: 100%;
  display: flex;
  padding: 20px 0px;
  align-items: center;
  flex-direction: column;
}
.app .service-box-load .service-box-load-data .row-title {
  gap: 8px;
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
}
.app .service-box-load .service-box-load-data .row-title .title,
.app .service-box-load .service-box-load-data .row-title .subtitle {
  height: 14px;
  cursor: progress;
  border-radius: 4px;
  animation: var(--loading);
}
.app .service-box-load .service-box-load-data .row-title .title {
  width: 200px;
}
.app .service-box-load .service-box-load-data .row-title .subtitle {
  width: 160px;
}
.app .service-box-load .service-box-load-data .button {
  width: 148px;
  height: 30px;
  border: none;
  cursor: progress;
  border-radius: 6px;
  animation: var(--loading);
}
.app .pricing-box {
  grid-column: span 6;
  display: flex;
  overflow: hidden;
  transition: var(--transition);
  border: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white-f5), var(--color-black-20));
}
.app .pricing-box .wrapper {
  gap: 20px;
  width: 100%;
  margin: 20px;
  display: flex;
  flex-direction: column;
}
.app .pricing-box .wrapper .title {
  width: 100%;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: var(--transition);
  font: bold 12px var(--font-mono-code);
  color: light-dark(var(--color-black-75), var(--color-white-99));
}
.app .pricing-box .wrapper .price {
  text-align: left;
  transition: var(--transition);
  font: bold 24px var(--font-mono-code);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .pricing-box .wrapper .line {
  border-bottom: 2px dashed var(--color-black-95);
}
.app .pricing-box .wrapper .features {
  margin: 0;
  padding: 0;
  list-style: none;
}
.app .pricing-box .wrapper .features li {
  display: flex;
  margin: 14px 0px;
  overflow: hidden;
  align-items: flex-start;
  color: var(--color-black-65);
  transition: var(--transition);
}
.app .pricing-box .wrapper .features li b {
  font: normal 12px var(--font-mono-code);
}
.app .pricing-box .wrapper .features li .icon {
  margin-right: 8px;
}
.app .pricing-box .wrapper .features li .text {
  text-wrap: nowrap;
  font: normal 12px var(--font-mono-code);
  line-height: 2;
}
.app .pricing-box .wrapper .features li:first-child {
  margin-top: 0px;
}
.app .pricing-box .wrapper .features li:last-child {
  margin-bottom: 0px;
}
.app .pricing-box .wrapper a {
  display: none;
  pointer-events: none;
}
.app .pricing-box:hover .wrapper .line {
  border-color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .pricing-box:hover .wrapper .features li b,
.app .pricing-box:hover .wrapper .features li .icon,
.app .pricing-box:hover .wrapper .features li .text {
  color: var(--color-black-28), var(--color-white-99) !important;
}
.app .pricing-box:hover .wrapper .ghost-button {
  color: light-dark(var(--color-white-ff), var(--color-black-20));
  background-color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .project-box {
  grid-column: span 8;
  width: 100%;
  display: flex;
  cursor: default;
  overflow: hidden;
  flex-direction: column;
  transition: var(--transition);
  border: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white-ff), var(--color-black-20));
}
.app .project-box .project-box-picture {
  width: 100%;
  min-height: 292px;
  max-height: 292px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  border-bottom: 1px solid light-dark(var(--color-white-e8), var(--color-black-35));
}
.app .project-box .project-box-picture .project-box-image {
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
}
.app .project-box .project-box-picture .projects-box-default {
  z-index: 1;
  height: 100%;
  display: flex;
  -o-object-fit: cover;
     object-fit: cover;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
}
.app .project-box .project-box-picture iframe {
  width: 100%;
  min-height: 100%;
  pointer-events: none;
  position: relative;
  overflow: hidden;
  border: none;
}
.app .project-box .project-box-data {
  gap: 10px;
  display: flex;
  padding: 16px 12px;
  align-items: center;
  flex-direction: column;
}
.app .project-box .project-box-data .project-box-title {
  width: calc(100% - 24px);
  min-width: -moz-max-content;
  min-width: max-content;
  text-align: center;
  letter-spacing: 0.4px;
  font: bold 16px var(--font-mono-code);
}
.app .project-box .project-box-data .project-box-label {
  width: calc(100% - 24px);
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 0.4px;
  font: normal 12px var(--font-mono-code);
}
.app .project-box .project-box-data .primary-button {
  height: 34px;
  padding: 0 14px;
  background: transparent;
  border-color: var(--border-color);
  font: normal 12px var(--font-mono-code);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .project-box .project-box-data .primary-button:hover {
  color: light-dark(var(--color-white-ff), var(--color-black-20));
  background-color: light-dark(var(--color-black-20), var(--color-white-ff));
}
.app .project-box:hover {
  box-shadow: 2px 2px 8px light-dark(var(--color-white-e8), var(--color-black-35));
}
.app .project-box-load {
  grid-column: span 8;
  width: 100%;
  display: flex;
  cursor: progress;
  overflow: hidden;
  flex-direction: column;
  transition: var(--transition);
  border: 1px solid var(--border-color);
}
.app .project-box-load .project-box-load-image {
  width: 100%;
  cursor: progress;
  min-height: 292px;
  max-height: 292px;
  animation: var(--loading);
}
.app .project-box-load .project-box-load-data {
  gap: 10px;
  display: flex;
  padding: 16px 12px;
  align-items: center;
  flex-direction: column;
}
.app .project-box-load .project-box-load-data .title {
  margin: 0px;
  width: 240px;
  height: 21px;
  cursor: progress;
  animation: var(--loading);
}
.app .project-box-load .project-box-load-data .label {
  width: 160px;
  height: 16px;
  cursor: progress;
  margin-top: -2px;
  margin-bottom: 10px;
  animation: var(--loading);
}
.app .project-box-load .project-box-load-data .button {
  cursor: progress;
}
.app .about .about-photo {
  display: flex;
  flex-direction: column;
  grid-column: span 12;
}
.app .about .about-photo figure {
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 20px);
}
.app .about .about-photo figure img {
  width: 100%;
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  transition: var(--transition);
  border: 2px solid var(--border-color);
}
.app .about .about-details {
  display: flex;
  flex-direction: column;
  grid-column: span 12;
}
.app .about .about-details h2 {
  margin: 0px;
  transition: var(--transition);
  font: bold 30px var(--font-mono-code);
}
.app .about .about-details h3 {
  transition: var(--transition);
  font: normal 16px var(--font-mono-code);
}
.app .about .about-details p {
  transition: var(--transition);
  font: normal 13px var(--font-mono-code);
  line-height: 2;
}
.app .service-page {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.app .service-page .column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.app .page {
  width: 100%;
  display: flex;
  min-height: 100vh;
}
.app .page .row {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.app .page .column {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.app .page .block {
  gap: 8px;
  width: 100%;
  display: flex;
  margin-bottom: 14px;
  flex-direction: column;
}
.app .page h1,
.app .page h2,
.app .page h3,
.app .page h4,
.app .page h5,
.app .page h6 {
  margin: 0px;
}
.app .page h1 {
  margin-bottom: 10px;
  font: bold 28px var(--font-mono-code);
}
.app .page h2 {
  font: normal 18px var(--font-mono-code);
}
.app .page b,
.app .page strong {
  font: bold 12px var(--font-mono-code);
  line-height: 2;
}
.app .page p {
  font: normal 12px var(--font-mono-code);
  line-height: 2;
}
.app .page ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.app .page ul li {
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  font: normal 12px var(--font-mono-code);
  line-height: 2.5;
}
.app .page ul li .icon {
  margin-right: 8px;
}
.app .page figure {
  margin: 0px;
  margin-right: 40px;
}
.app .page figure img {
  width: 100%;
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  -o-object-position: center;
     object-position: center;
  box-sizing: border-box;
}

.app.dark {
  color: var(--color-white-ff) !important;
  background-color: var(--color-black-12) !important;
}
.app.dark .header {
  color: var(--color-white-ff) !important;
  border-color: var(--color-black-35) !important;
  background-color: var(--color-black-12) !important;
}
.app.dark .header .container .flex .logo span {
  color: var(--color-white-ff) !important;
}
.app.dark .header .container .flex .nav-menu .nav-list .nav-item .nav-link {
  color: var(--color-white-ff) !important;
}
.app.dark .menu {
  border-color: var(--color-black-35) !important;
  background-color: var(--color-black-20) !important;
}
.app.dark .menu .menu-header {
  border-color: var(--color-black-35) !important;
}
.app.dark .menu .menu-header .menu-title {
  color: var(--color-white-ff) !important;
}
.app.dark .menu .menu-content .nav-menu .nav-list .nav-item {
  border-color: var(--color-black-35) !important;
}
.app.dark .menu .menu-content .nav-menu .nav-list .nav-item .nav-link {
  color: var(--color-inactive) !important;
}
.app.dark .menu .menu-content .nav-menu .nav-list .nav-item .nav-link:hover {
  color: var(--color-white-ff) !important;
}
.app.dark .footer {
  border-color: var(--color-black-35);
}
.app.dark .footer .container {
  border-color: var(--color-black-35) !important;
}
.app.dark .footer .container .row-socials .socials-list .social-item .social-link {
  color: var(--color-white-ff) !important;
}
.app.dark .footer .container .row-copyright span {
  color: var(--color-white-ff) !important;
}
.app.dark hr {
  background-color: var(--color-black-35) !important;
}
.app.dark .form {
  border-color: var(--color-black-35) !important;
  background-color: var(--color-black-20) !important;
}
.app.dark .form .block input,
.app.dark .form .block textarea,
.app.dark .form .form-block input,
.app.dark .form .form-block textarea {
  color: var(--color-white-ff) !important;
  border-color: var(--color-black-35) !important;
  background-color: var(--color-black-18) !important;
}
.app.dark .row-caption h1,
.app.dark .row-caption h2,
.app.dark .row-caption p {
  color: var(--color-white-ff) !important;
}
.app.dark .service-item .service-item-image {
  border-color: var(--color-black-35) !important;
}
.app.dark .service-item .service-item-data h2,
.app.dark .service-item .service-item-data p {
  color: var(--color-white-ff) !important;
}
.app.dark .pricing-box {
  border-color: var(--color-black-35) !important;
  background-color: var(--color-black-20) !important;
}
.app.dark .pricing-box .wrapper .price {
  color: var(--color-white-ff) !important;
}
.app.dark .pricing-box .wrapper .features li hr {
  background-color: var(--color-black-35) !important;
}
.app.dark .project-box {
  border-color: var(--color-black-35) !important;
  background-color: var(--color-black-20) !important;
}
.app.dark .project-box .project-box-picture {
  border-color: var(--color-black-35) !important;
}
.app.dark .project-box .project-box-data .project-box-title {
  color: var(--color-white-ff) !important;
}
.app.dark .project-box .project-box-data .project-box-label {
  color: var(--color-inactive) !important;
}
.app.dark .project-box:hover {
  box-shadow: 2px 2px 8px var(--color-black-35);
}
.app.dark .project-box-load {
  border-color: var(--color-black-35) !important;
  background-color: var(--color-black-20) !important;
}
.app.dark .project-box-load .project-box-load-image {
  animation: var(--loading-dark) !important;
}
.app.dark .project-box-load .project-box-load-data .title,
.app.dark .project-box-load .project-box-load-data .label {
  animation: var(--loading-dark) !important;
}
.app.dark .about .about-photo figure img {
  border-color: var(--color-black-35) !important;
}
.app.dark .about .about-details h2 {
  color: var(--color-white-ff);
}
.app.dark .about .about-details h3 {
  color: var(--color-inactive);
}
.app.dark .about .about-details p {
  color: var(--color-white-fa);
}

.app.no-scroll {
  position: fixed;
}

.maintenance {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
  background-color: light-dark(var(--color-white-fa), var(--color-black-18));
}
.maintenance .blur {
  inset: 0;
  z-index: 1;
  width: 100%;
  position: fixed;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.maintenance .container {
  z-index: 2;
  display: flex;
  margin: 0px 32px;
  width: var(--wrapper);
  align-items: flex-start;
  justify-content: center;
}
.maintenance .container .flex-column {
  gap: 30px;
  width: 100%;
  display: flex;
  padding: 30px 40px;
  align-items: center;
  border-radius: 12px;
  flex-direction: column;
  justify-content: flex-start;
}
.maintenance .container .flex-column:last-child {
  border: 1px solid var(--border-color);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
  background-color: light-dark(var(--color-white-ff), var(--color-black-20));
}
.maintenance .box-notice {
  width: 380px;
  display: flex;
  padding: 20px 40px;
  border-radius: 12px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-attachment: fixed;
  border: 1px solid var(--border-color);
  background-color: var(--color-black-20);
}
.maintenance .box-notice h1 {
  width: calc(100% - 40px);
  text-align: center;
  text-wrap: balance;
  font: bold 30px var(--font-mono);
}
.maintenance .box-notice h3 {
  font: bold 14px var(--font-mono);
}
.maintenance .box-notice p {
  width: calc(100% - 80px);
  color: #aaaaaa;
  text-wrap: balance;
  text-align: center;
  line-height: 2 !important;
  font: normal 12px var(--font-mono-code);
}
.maintenance .box-notice .countdown {
  gap: 20px;
  width: 100%;
  display: flex;
  padding: 20px 0px;
  align-items: center;
  justify-content: center;
}
.maintenance .box-notice .countdown .item {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.maintenance .box-notice .countdown .item span:first-child {
  color: var(--color-white-ff);
  font: bold 14px var(--font-mono);
}
.maintenance .box-notice .countdown .item span:last-child {
  font: normal 12px var(--font-mono);
  color: #aaaaaa;
}
.maintenance .row-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.maintenance .row-title h1,
.maintenance .row-title h2 {
  margin: 0px;
  text-wrap: balance;
  font: bold 20px var(--font-mono);
}
.maintenance .row-content {
  width: 100%;
  display: flex;
}
.maintenance .row-content .form {
  gap: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.maintenance .row-content .form .block {
  gap: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.maintenance .row-content .form .block input[type=text],
.maintenance .row-content .form .block input[type=email],
.maintenance .row-content .form .block textarea {
  width: 100%;
  height: 34px;
  text-indent: 14px;
  border-radius: 4px;
  line-height: 2 !important;
  font: normal 12px var(--font-mono);
  border: 1px solid var(--border-color);
  background-color: light-dark(var(--color-white-fa), var(--color-black-18));
}
.maintenance .row-content .form .block input[type=text]:focus-visible,
.maintenance .row-content .form .block input[type=email]:focus-visible,
.maintenance .row-content .form .block textarea:focus-visible {
  outline: none;
}
.maintenance .row-content .form .block textarea {
  padding: 10px 14px;
  text-indent: 0px;
  min-width: 514px;
  max-width: 514px;
  min-height: 200px;
  max-height: 200px;
  text-align: left;
  text-wrap: balance;
  overflow: hidden auto;
}
.maintenance .row-content .form .block textarea::-moz-selection {
  background-color: var(--color-blue);
}
.maintenance .row-content .form .block textarea::selection {
  background-color: var(--color-blue);
}
.maintenance .row-content .form .block .icon-button {
  width: 38px;
  height: 38px;
  display: flex;
  cursor: pointer;
  min-width: 38px;
  min-height: 38px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  color: light-dark(var(--color-black-20), var(--color-white-ff));
  background-color: light-dark(var(--color-white-fa), var(--color-black-18));
}
.maintenance .row-content .form .block .primary-button {
  width: 100%;
  height: 38px;
  border: none;
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  letter-spacing: 0.4px;
  justify-content: center;
  color: var(--color-white-ff);
  font: normal 12px var(--font-mono);
  background-color: light-dark(var(--color-black-20), var(--color-black-12));
}
.maintenance .row-socials {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.maintenance .row-socials .socials-list {
  gap: 14px;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  justify-content: center;
}
.maintenance .row-socials .socials-list .social-item {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.maintenance .row-socials .socials-list .social-item .social-link {
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  color: light-dark(var(--color-black-20), var(--color-inactive));
}

@media (prefers-color-scheme: light) {
  .banner,
  .maintenance {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-image: url(../../../public/assets/city-i.png);
  }
}
@media (prefers-color-scheme: dark) {
  .banner,
  .maintenance {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-image: url(../../../public//assets/city-ii.png);
  }
}
@media screen and (max-width: 1242px) {
  .pricing-box {
    grid-column: span 8 !important;
  }
  .project-box .project-box-picture,
  .project-box .project-box-load-image,
  .project-box-load .project-box-picture,
  .project-box-load .project-box-load-image {
    min-height: 222px !important;
  }
  .project-box .project-box-picture .projects-box-default,
  .project-box .project-box-load-image .projects-box-default,
  .project-box-load .project-box-picture .projects-box-default,
  .project-box-load .project-box-load-image .projects-box-default {
    max-height: 145px !important;
  }
}
@media screen and (max-width: 64em) {
  .top-bar .container .flex-action {
    display: none !important;
  }
  .header .container .flex .logo span {
    font-size: 20px !important;
  }
  .header .container .flex .nav-menu {
    display: none !important;
  }
  .banner {
    height: calc(100vh - 110px) !important;
  }
  .banner .container .banner-box .title {
    font-size: 28px !important;
  }
  .banner .container .banner-box .subtitle {
    font-size: 12px !important;
  }
  .pricing-box {
    grid-column: span 12 !important;
  }
  .project-box {
    grid-column: span 12 !important;
  }
  .service-item .service-item-image {
    min-width: 300px !important;
  }
  .service-item .service-item-data h2 {
    font-size: 20px !important;
    line-height: 1.8 !important;
    margin-bottom: 10px !important;
  }
  .service-item .service-item-data p {
    font-size: 14px !important;
  }
  .service-box-load {
    grid-column: span 12 !important;
  }
  .service-box-load .service-box-load-image {
    height: 204.94px;
  }
  .service-box-load .service-box-load-data .row-title .title {
    width: 240px !important;
  }
  .service-box-load .service-box-load-data .row-title .subtitle {
    display: none !important;
  }
  .about .about-photo {
    margin-bottom: 20px;
    grid-column: span 24 !important;
  }
  .about .about-photo figure {
    width: 100% !important;
  }
  .about .about-photo figure img {
    width: calc(100% - 4px) !important;
  }
  .about .about-details {
    align-items: center !important;
    grid-column: span 24 !important;
  }
  .about .about-details p {
    text-align: center !important;
    text-wrap: balance !important;
  }
}
@media screen and (max-width: 40em) {
  .top-bar .container .flex-action,
  .top-bar .container .flex-socials {
    display: none !important;
  }
  .banner .container .banner-box {
    padding: 40px 20px !important;
  }
  .banner .container .banner-box .title {
    font-size: 22px !important;
  }
  .banner .container .banner-box .subtitle {
    font-size: 10px !important;
  }
  .service-item {
    flex-direction: column;
  }
  .service-item .service-item-image {
    min-width: auto !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .service-item .service-item-data {
    margin-top: 18px !important;
    align-items: center !important;
  }
  .service-item .service-item-data h2,
  .service-item .service-item-data p {
    text-align: center !important;
  }
  .service-item .service-item-data h2 {
    font-size: 16px !important;
  }
  .service-item .service-item-data p {
    font-size: 12px !important;
  }
  .service-item:nth-child(even) {
    padding: 0 0 40px !important;
    transform: rotate(0deg) !important;
  }
  .service-item:nth-child(even) .service-item-image {
    margin-right: 0 !important;
  }
  .service-item:nth-child(even) .service-item-image,
  .service-item:nth-child(even) .service-item-data {
    transform: rotate(0deg) !important;
  }
  .pricing-box {
    grid-column: span 24 !important;
  }
  .project-box {
    grid-column: span 24 !important;
  }
  .project-box .project-box-picture {
    height: 210px !important;
  }
  .project-box-load {
    grid-column: span 24 !important;
  }
  .maintenance .box-notice {
    padding: 20px 0px;
  }
  .maintenance .box-notice h1 {
    font-size: 24px;
  }
}/*# sourceMappingURL=app.css.map */